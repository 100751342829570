<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="1200px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="purple" class="mr-4" outlined v-on="on">
        <v-icon class="mr-2">mdi-format-list-bulleted</v-icon>
        Disponibilità
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Gestione Disponibilità
      </v-card-title>
      <v-card-subtitle class="mt-1">
        <v-row no-gutters>
          Anno di Riferimento:
          <span style="font-size: 20px" class="green--text ml-2"
            ><b>{{ anno_rif }}</b></span
          >
        </v-row>
        <v-row>
            <v-col cols="4">
              <v-btn icon class="ml-4" @click="expand_all = !expand_all"><v-icon>{{!expand_all ? 'mdi-expand-all' : 'mdi-collapse-all'}}</v-icon></v-btn>
            </v-col>
            <v-col cols="1" class="d-flex justify-center">
                Prodotta
            </v-col>
            <v-col cols="2" class="d-flex justify-center">
                Ordinata
            </v-col>
            <v-col cols="2" class="d-flex justify-center">
                Rimanenza
            </v-col>
            <v-col cols="2">
                Clienti
            </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <GruppoDiamentroDispo
          v-for="categ in crisantemi"
          :categ="categ"
          :key="categ.id"
          :anno_rif="anno_rif"
          :expand_all="expand_all"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="dialog = false">Annulla</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";
import GruppoDiamentroDispo from "./gruppo_diametro_dispo.vue";
export default {
  components: {
    GruppoDiamentroDispo,
  },
  props:{
    anno_rif: String
  },
  data() {
    return {
      dialog: false,
      expand_all: false
    };
  },
  computed: {
    ...mapGetters({
      prodotti: "get_lista_prodotti_raw",
    }),
    crisantemi() {
      let cris = this.prodotti.filter(
        (prod) => prod.sotto_categoria == "Crisantemo"
      );
      var obj = [];
      var res = _.groupBy(cris, "diametro");
      Object.keys(res).forEach((key) => {
        obj.push({ diametro: key, prodotti: res[key] });
      });
      return obj;
    },
    anni() {
      let adesso = moment().format("YYYY");
      return [...Array(5).keys()].map((anno) => adesso - anno);
    },
  },
};
</script>
