<template>
  <v-dialog v-model="dialog" width="1200" persistent scrollable>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Nuova Prenotazione Crisantemi
      </v-card-title>
      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="8">
            <v-autocomplete
              prepend-inner-icon="mdi-account"
              class=""
              label="Seleziona Cliente"
              v-model="cliente_sel"
              outlined
              :items="clienti"
              item-text="nome"
              item-value="iva"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col cols="2" class="d-flex align-center justify-center">
            <span style="font-size: 30px" class="pink--text"
              ><b>{{ numero_cliente }}</b></span
            >
            <!-- <v-text-field
              readonly
              outlined
              label="numero"
              v-model="numero_cliente"
            /> -->
          </v-col>
          <v-col cols="2" class="d-flex align-center justify-center">
            <v-menu>
              <template v-slot:activator="{ on }">
                <span
                  style="font-size: 30px; cursor: pointer"
                  class="green--text"
                  v-on="on"
                  ><b>{{ anno_rif }}</b></span
                >
              </template>
              <v-list>
                <v-list-item
                  v-for="anno in anni"
                  :key="anno"
                  @click="anno_rif = anno"
                >
                  <v-list-item-avatar>
                    <v-icon>mdi-calendar</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ anno }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <v-select
              :items="anni"
              v-model="anno_rif"
              :value="anno_rif"
              outlined
              label="Anno"
              hide-details
            ></v-select> -->
          </v-col>
        </v-row>
        <template v-if="cliente_sel && !esiste">
          <v-row>
            <v-row align="center">
              <v-col cols="3">
                <v-menu v-model="menu_clona">
                  <template v-slot:activator="{ on }">
                    <v-btn outlined v-on="on" class="ml-3" color="purple"
                      >Clona Da ...</v-btn
                    >
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="anno in anni"
                      :key="anno"
                      @click="clona(anno)"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ anno }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <span class="red--text" v-if="not_exist"
                  ><v-icon class="mr-2" color="red">mdi-alert</v-icon> non
                  esistente</span
                >
              </v-col>
            </v-row>

            <v-col cols="12">
              <v-list>
                <CategoriaVaso
                  v-for="categ in crisantemi"
                  :categ="categ"
                  :key="categ.id"
                  :anno_rif="anno_rif"
                  :cliente="cliente_sel"
                />
              </v-list>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="extra_item_sel"
                :items="prodotti_nocris"
                item-text="nome"
                item-value="id"
                outlined
              />
            </v-col>
          </v-row>

          <template v-if="extra_item_sel">
            <ExtraItem
              :id="extra_item_sel"
              :cliente="cliente_sel"
              :anno_rif="anno_rif"
            />
          </template>
          <v-row>
            <v-col cols="10">
              <v-text-field
                outlined
                label="Note"
                v-model="nota_text"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <v-row v-if="esiste" justify="center">
          <v-icon color="blue" class="mr-2">mdi-alert</v-icon> Prenotazione già
          esistente
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="close()">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import nuovo_ordine_crisantemi_mixin from "../../mixin/nuovo_ordine_crisantemi_mixin";
import CategoriaVaso from "./gruppo_diametro_nuovo_ordine.vue";
import ExtraItem from "./extra_item_element.vue";
import _ from "lodash";
export default {
  components: {
    CategoriaVaso,
    ExtraItem,
  },
  mixins: [nuovo_ordine_crisantemi_mixin],
  data() {
    return {
      dialog: false,
      extra_item_sel: null,
      nota_text: "",
    };
  },
  created() {
    this.debouncenota = _.debounce(this.set_nota, 1500);
  },
  watch: {
    nota_text: function () {
      this.debouncenota();
    },
  },
  computed: {},
  methods: {
    open() {
      console.log("open");
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    set_nota() {
      this.$store.dispatch("invio_nota_crisantemi", {
        anno: this.anno_rif,
        iva: this.cliente_sel,
        nota: this.nota_text,
      });
    },
  },
};
</script>
