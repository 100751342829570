<template>
  <v-hover v-slot="{ hover }">
    <v-row
      no-gutters
      align="center"
      :class="[prenotazione.evasa ? 'text-decoration-line-through grey--text text--lighten-1' : '',  hover ? 'green lighten-5' : '']"
    >
      <v-col cols="1" class="pl-2">
        {{ cliente.numero }}
      </v-col>
      <v-col cols="3">
        {{ cliente.nome }}
      </v-col>
      <v-col cols="2">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <span style="cursor: pointer" v-on="on">
              {{
                prenotazione.ts_consegna_presunta
                  ? data_presunta_format
                  : "Non Specificato"
              }}
            </span>
          </template>
          <v-date-picker
            v-model="data_presunta"
            scrollable
            locale="it-it"
            @input="modal_date = false"
          />
        </v-menu>
      </v-col>
      <v-col cols="1">
        <v-menu
          v-model="menu_cambio_ritiro"
          min-width="200"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <span
              :class="!prenotazione.ritiro ? 'blue--text' : 'green--text'"
              style="cursor: pointer"
              v-on="on"
              >{{ !prenotazione.ritiro ? "Consegna" : "Ritiro" }}</span
            >
          </template>
          <v-list>
            <v-list-item @click="set_ritiro(1)">
              <v-list-item-content>
                <v-list-item-title> Ritiro </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-progress-circular
                  indeterminate
                  size="20"
                  width="2"
                  color="green"
                  v-if="loading_cambio_ritiro"
                ></v-progress-circular>
              </v-list-item-action>
            </v-list-item>
            <v-list-item @click="set_ritiro(0)">
              <v-list-item-content>
                <v-list-item-title> Consegna </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="1">
        {{ prenotazione.anno }}
      </v-col>
      <v-col cols="1">
        {{ tot_cc }}
      </v-col>
      <v-col cols="1"> {{ totale_valore }} € </v-col>
      <v-col cols="2">
        <v-btn icon color="blue" @click="$refs.prenotazione_dettaglio.open()"
          ><v-icon>mdi-open-in-new</v-icon></v-btn
        >
        <DettaglioPrenotazione
          :prenotazione="prenotazione"
          ref="prenotazione_dettaglio"
        />
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn color="grey" icon v-on="on"
              ><v-icon>mdi-dots-vertical</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-dialog width="600" v-model="dialog_elimina">
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-avatar>
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Elimina</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-card>
                <v-card-title class="red--text">
                  Elimina Prenotazione
                </v-card-title>
                <v-card-text>
                  La prenotazione verrà eliminata e non sarà possibile
                  recuperarla. <br />
                  Vuoi procedere?
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="red" outlined @click="elimina_prenotazione()"
                    ><v-icon class="mr-2">mdi-delete</v-icon>Elimina</v-btn
                  >
                  <v-btn color="grey" text @click="dialog_elimina = false"
                    >Annulla</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-list-item @click="stampa()">
              <v-list-item-avatar
                ><v-icon>mdi-printer</v-icon></v-list-item-avatar
              >
              <v-list-item-content>
                <v-list-item-title>Stampa</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-action>
                <v-checkbox v-model="evasa"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Evasa</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-hover>
</template>

<script>
import moment from "moment";
import printer from "../../../service/printer";
import DettaglioPrenotazione from "./dettaglio_prenotazione/dettaglio_prenotazione_dialog.vue";
export default {
  components: {
    DettaglioPrenotazione,
  },
  props: {
    prenotazione: Object,
  },
  data() {
    return {
      data_presunta: null,
      dialog_elimina: false,
      menu_cambio_ritiro: false,
      loading_cambio_ritiro: false,
      date: moment.unix(this.prenotazione.ts_consegna_presunta),
      modal_date: false,
      modal_time: false,
      time: moment.unix(this.prenotazione.ts_consegna_presunta).hour,
      evasa: this.prenotazione.evasa,
    };
  },
  watch: {
    data_presunta: function () {
      this.$store.dispatch("cambia_data_prenotazione_crisantemi", {
        id_prenotazione: this.prenotazione.id,
        ts_data: moment(this.data_presunta).format("X"),
      });
    },
    evasa: function () {
      this.$store.dispatch("cambia_evasione_prenotazione_crisantemi", {
        id_prenotazione: this.prenotazione.id,
        evasa: this.evasa,
      });
    },
  },
  computed: {
    cliente() {
      return this.$store.getters.get_cliente_by_iva(this.prenotazione.cliente);
    },
    tot_cc() {
      return this.$store.getters.get_totCC_prenotazione(this.prenotazione.id);
    },
    totale_valore() {
      return this.$store.getters.get_totValore_prenotazione(
        this.prenotazione.id
      );
    },
    data_presunta_format() {
      return moment
        .unix(this.prenotazione.ts_consegna_presunta)
        .format("dddd DD / MM");
    },
    dateFormatter() {
      return this.data_presunta
        ? moment(this.data_presunta).format("ddd D MMM")
        : "";
    },
  },
  methods: {
    elimina_prenotazione() {
      this.$store.dispatch(
        "elimina_prenotazione_crisantemi",
        this.prenotazione.id
      );
    },
    stampa() {
      printer.print_pdf_ordine_crisantemi(this.prenotazione.id);
    },
    set_ritiro(ritiro) {
      this.loading_cambio_ritiro = true;
      this.$store
        .dispatch("cambia_ritiro_crisantemi", {
          id_prenotazione: this.prenotazione.id,
          ritiro: ritiro,
        })
        .then(() => {
          this.loading_cambio_ritiro = false;
          this.menu_cambio_ritiro = false;
        });
    },
  },
};
</script>
