<template>
  <v-row align="center" class="ml-2" no-gutters :class="colore_sel == colore.id ? 'green lighten-4' : ''">
    <v-col cols="1">
      <v-avatar>
        <v-img :src="avatar_colore"></v-img>
      </v-avatar>
    </v-col>
    <v-hover v-slot="{ hover }">
      <v-col cols="3" :class="box_sel ? 'green--text font-weight-bold' : ''">
        {{ colore.nome }}
        <template v-if="colore.nota">
          <i>( {{ colore.nota }} )</i>
        </template>
        <v-menu v-model="menu_nota" :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn icon small class="ml-2" v-show="hover" v-on="on"
              ><v-icon>mdi-pencil</v-icon></v-btn
            >
          </template>
          <v-text-field v-model="text_nota" hide-details="" outlined :class="colore_sel == colore.id ? 'red' : ''"></v-text-field>
        </v-menu>
      </v-col>
    </v-hover>
    <v-col cols="1" class=" pl-4 pr-1" v-for="consegna in consegne" :key="consegna.id">
      <ElementoConsegna
        :prenotazione="prenotazione"
        :colore="colore.id"
        :consegna="consegna"
        :prodotto="prodotto"
        :qta_colore="qta_colore != ''"
      />
    </v-col>
    <v-col cols="2" :offset="6 - consegne.length">
      <v-text-field
        ref="qta_box"
        v-model="qta_colore"
        dense
        hide-details
        outlined
        :readonly="locked"
        :prefix="qta_colore_rimanenza + ' / '"
        :error="qta_colore_rimanenza < 0"
        :success="qta_colore_rimanenza == 0"
        @focus="select_text"
        @blur="deselect_and_lock"
      >
        <template v-slot:append>
          <v-progress-circular
            size="15"
            width="1"
            indeterminate
            v-if="loading_qta"
          />
        </template>
        <template v-slot:append-outer>
          <v-icon color="grey lighten-1" @click="unlock_and_select" size="18">{{
            locked ? "mdi-lock" : "mdi-lock-open-variant"
          }}</v-icon>
        </template>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash";
import ElementoConsegna from "./elemento_consegna.vue";
export default {
  components: {
    ElementoConsegna,
  },
  props: {
    prenotazione: String,
    colore: Object,
    prodotto: String,
    cliente: String,
    anno_rif: String,
    mostra_tutto: Boolean,
    consegne: Array,
    colore_sel: String
  },
  created() {
    this.debounceqta = _.debounce(this.set_qta_colore, 1500);
    this.debouncenota = _.debounce(this.set_nota, 2000);
  },
  data() {
    return {
      qta_colore: this.colore.qta ? this.colore.qta : 0,
      loading_qta: false,
      box_sel: false,
      locked: true,
      menu_nota: false,
      text_nota: this.colore.nota,
    };
  },
  watch: {
    qta_colore: function () {
      if (isNaN(this.qta_colore)) {
        return;
      }
      if (this.qta_colore < 0) {
        this.qta_colore = 0;
      } else if (this.qta_colore > 500) {
        this.qta_colore = 500;
      }
      this.debounceqta();
    },
    text_nota: function () {
      this.debouncenota();
    },
    show: function () {
      if (this.show) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
  computed: {
    qta_colore_rimanenza() {
      return (
        this.qta_colore -
        this.consegne
          .map((consegna) => {
            let prodotto = consegna.prodotti.find(
              (prodotto) => prodotto.id_prodotto == this.prodotto
            );
            if (!prodotto) {
              return 0;
            }
            let colore = prodotto.colori.find(
              (col) => col.id == this.colore.id
            );
            if (!colore) {
              return 0;
            }
            return colore.qta;
          })
          .reduce((tot, num) => tot + num, 0)
      );
    },
    avatar_colore() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto +
        "/img_colore/" +
        this.colore.id
      );
    },
  },
  methods: {
    set_qta_colore() {
      this.loading_qta = true;
      this.$store
        .dispatch("invio_colore_crisantemi", {
          anno: this.anno_rif,
          iva: this.cliente,
          id_prodotto: this.prodotto,
          id_colore: this.colore.id,
          qta: this.qta_colore,
        })
        .finally(() => {
          this.loading_qta = false;
        });
    },
    set_nota() {
      this.$store
        .dispatch("invio_nota_colore_crisantemi", {
          anno: this.anno_rif,
          iva: this.cliente,
          id_prodotto: this.prodotto,
          id_colore: this.colore.id,
          nota: this.text_nota,
        })
        .then(() => {
          this.menu_nota = false;
        });
    },
    unlock_and_select() {
      if (this.locked) {
        this.locked = false;
        this.$refs.qta_box.focus();
      }
    },
    deselect_and_lock() {
      if (!this.locked) {
        this.box_sel = false;
        setTimeout(() => {
          this.locked = true;
        }, 300);
      }
    },
    select_text: function (e) {
      if (!this.locked) {
        this.box_sel = true;
        e.target.select();
      }
    },
  },
};
</script>
