<template>
  <v-text-field
    :disabled="!qta_colore"
    v-model="qta_prenotata"
    outlined
    dense
    hide-details
    :loading="loading"
    type="number"
    @focus="select_text"
  ></v-text-field>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    prenotazione: String,
    prodotto: String,
    colore: String,
    consegna: Object,
    qta_colore: Boolean,
  },
  mounted() {
    this.debounceqta = _.debounce(this.set_qta_colore, 1500);
  },
  data() {
    return {
      qta_to_send: null,
      loading: false,
    };
  },
  watch: {
    qta_to_send: function () {
      if (isNaN(this.qta_to_send)) {
        return;
      }
      if (this.qta_to_send < 0) {
        this.qta_to_send = 0;
      }
      this.debounceqta();
    },
  },
  computed: {
    qta_prenotata: {
      get() {
        let prodotto = this.consegna.prodotti.find(
          (prod) => prod.id_prodotto == this.prodotto
        );
        if (prodotto) {
          let colore = prodotto.colori.find((col) => col.id == this.colore);
          if (colore) {
            return parseInt(colore.qta);
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      },
      set(qta) {
        this.qta_to_send = qta;
      },
    },
  },
  methods: {
    set_qta_colore() {
      this.loading = true;
      this.$store
        .dispatch("aggiorna_consegna_prenotazione_crisantemi", {
          id_prenotazione: this.prenotazione,
          id_consegna: this.consegna.id,
          id_prodotto: this.prodotto,
          id_colore: this.colore,
          qta: this.qta_to_send,
        })
        .finally(() => {
          this.loading = false;
        });
    },
    select_text: function (e) {
      e.target.select();
    },
  },
};
</script>
