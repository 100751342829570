<template>
  <div>
    <v-list-item>
      <v-list-item-avatar
        ><v-icon>mdi-bucket-outline</v-icon></v-list-item-avatar
      >
      <v-list-item-content>
        <v-list-item-title class="font-italic grey--text">
          Vaso Ø {{ categ.diametro }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon color="grey" @click="show = !show"
          ><v-icon>{{
            show ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon></v-btn
        >
      </v-list-item-action>
    </v-list-item>
    <template v-if="show">
      <template v-for="prod in categ.prodotti">
        <ElementoProdotto
          :prenotazione="prenotazione"
          :prodotto="prod"
          :consegne="consegne"
          :key="prod.id"
          :cliente="cliente"
          :anno_rif="anno_rif"
          :mostra_tutto="mostra_tutto"
          :colore_sel="colore_sel"
        />
      </template>
    </template>
         <v-divider class="mt-8"/>
  </div>
</template>

<script>
import ElementoProdotto from "./elemento_prodotto.vue";
export default {
  components: {
    ElementoProdotto,
  },
  props: {
    prenotazione: String,
    categ: Object,
    consegne: Array,
    cliente: String,
    anno_rif: String,
    mostra_tutto: Boolean,
    colore_sel: String
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>