<template>
  <v-row align="center" class="ml-1 my-n4">
    <v-col cols="1">
      <v-avatar>
        <v-img :src="avatar_colore"></v-img>
      </v-avatar>
    </v-col>
    <v-col cols="3">
      {{ colore.nome }}
    </v-col>
    <v-col cols="1">
      <v-text-field
        v-model="qta_colore"
        dense
        hide-details
        outlined
        @focus="select_text"
      >
        <template v-slot:append>
          <v-progress-circular
            size="15"
            width="1"
            indeterminate
            v-if="loading_qta"
          />
        </template>
      </v-text-field>
    </v-col>
    <v-col cols="2" class="d-flex justify-center">
      <b>{{ ordinato.qta }}</b>
    </v-col>
    <v-col cols="2" class="d-flex justify-center">
      <span
        :class="qta_colore - ordinato.qta < 0 ? 'red--text' : 'green--text'"
        >{{ qta_colore - ordinato.qta }}</span
      >
    </v-col>
    <v-col cols="2">
      <v-dialog
        v-model="dialog"
        scrollable
        max-width="500px"
        transition="dialog-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon color="grey" v-on="on">
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="grey--text text-subtitle-1">
            Clienti
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="cliente in ordinato.clienti.sort((a,b) => b.qta - a.qta)"
                :key="cliente.iva"
                class="my-n2"
                @click="apri_dialog_prenotazione(cliente.id_pren)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    ><b class="mr-4">{{ get_cliente(cliente.iva).numero }}</b>
                    {{ get_cliente(cliente.iva).nome }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action-text style="font-size: 20px">
                  {{ cliente.qta }}
                </v-list-item-action-text>
              </v-list-item>
            </v-list>
            <DettaglioPrenotazione
              v-if="prenotazione_corrente"
              ref="prenotazione"
              :prenotazione="prenotazione_corrente"
              :colore_sel="colore.id"
              @close="prenotazione_corrente = null"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="grey" text @click="dialog = false">chiudi</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash";
import DettaglioPrenotazione from "../dettaglio_prenotazione/dettaglio_prenotazione_dialog.vue";
export default {
  components: {
    DettaglioPrenotazione,
  },
  props: {
    colore: Object,
    prodotto: String,
    anno_rif: String,
  },
  created() {
    this.debounceqta = _.debounce(this.set_qta_colore, 1500);
  },
  data() {
    return {
      qta_to_send: null,
      loading_qta: false,
      dialog: false,
      prenotazione_corrente: null,
    };
  },
  watch: {
    qta_to_send: function () {
      if (isNaN(this.qta_colore)) {
        return;
      }
      if (this.qta_colore < 0) {
        this.qta_colore = 0;
      }
      this.debounceqta();
    },
    show: function () {
      if (this.show) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
  computed: {
    qta_colore: {
      set(qta) {
        this.qta_to_send = qta;
      },
      get() {
        return this.$store.getters.get_qta_dispo_crisantemo({
          anno: this.anno_rif,
          prodotto: this.prodotto,
          colore: this.colore.id,
        });
      },
    },
    ordinato() {
      return this.$store.getters.get_qta_dispo_ordinata_crisantemo({
        anno: this.anno_rif,
        prodotto: this.prodotto,
        colore: this.colore.id,
      });
    },
    avatar_colore() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto +
        "/img_colore/" +
        this.colore.id
      );
    },
  },
  methods: {
    set_qta_colore() {
      this.loading_qta = true;
      this.$store
        .dispatch("aggiorna_qta_dispo_crisantemi", {
          anno: this.anno_rif,
          id_prodotto: this.prodotto,
          id_colore: this.colore.id,
          qta: this.qta_to_send,
        })
        .finally(() => {
          this.loading_qta = false;
        });
    },
    get_cliente(iva) {
      return this.$store.getters.get_cliente_by_iva(iva);
    },
    select_text: function (e) {
      e.target.select();
    },
    apri_dialog_prenotazione(id_prenotazione) {
      this.prenotazione_corrente =
        this.$store.getters.get_prenotazione_crisantemi_by_id(id_prenotazione);
      setTimeout(() => {
        this.$refs.prenotazione.open();
      }, 200);
    },
  },
};
</script>