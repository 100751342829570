<template>
  <div>
    <!-- <v-list-item
      class="my-6"
      @click="show_color = !show_color"
      :class="show_color ? 'grey lighten-3' : ''"
    >
      <v-list-item-avatar> </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <i>{{ nome_split[0] }}</i>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action-text style="font-size: 20px" class="mr-4">
        {{ n_totale_prodotto }}
      </v-list-item-action-text>
    </v-list-item> -->
    <v-row
      class="my-6"
      @click="show_color = !show_color"
      :class="show_color ? 'grey lighten-3' : ''"
      style="cursor: pointer"
    >
      <v-col cols="4" style="font-size: 20px" class="pl-4">
        <i>{{ nome_split[0] }}</i>
      </v-col>
      <v-col
        cols="1"
        v-for="consegna in consegne"
        :key="consegna.id"
        style="font-size: 20px"
        class="d-flex justify-end pr-0"
      >
        <span
          :class="
            n_contato_consegna(consegna.id) != n_totale_consegna(consegna)
              ? 'red--text'
              : 'green--text'
          "
          class="mr-2"
          >{{ n_contato_consegna(consegna.id) }}</span
        >
        /
        {{ n_totale_consegna(consegna) }}
      </v-col>
      <v-col
        cols="1"
        :offset="7 - consegne.length"
        style="font-size: 20px"
        class="d-flex justify-end pr-12"
      >
        {{ n_totale_prodotto }}
      </v-col>
    </v-row>

    <template v-if="show_color">
      <template v-for="colore in prodotto.colori">
        <ElementoColore
          v-if="mostra_tutto || colore.qta > 0"
          :prenotazione="prenotazione"
          :key="colore.id"
          :colore="colore"
          :prodotto="prodotto.id"
          :anno_rif="anno_rif"
          :cliente="cliente"
          :mostra_tutto="mostra_tutto"
          :consegne="consegne"
          :colore_sel="colore_sel"
        />
      </template>
    </template>
  </div>
</template>

<script>
import ElementoColore from "./elemento_colore.vue";
export default {
  components: {
    ElementoColore,
  },
  props: {
    prenotazione: String,
    prodotto: Object,
    consegne: Array,
    cliente: String,
    anno_rif: String,
    mostra_tutto: Boolean,
    colore_sel: String
  },
  data() {
    return {
      show_color: true,
    };
  },
  computed: {
    avatar_img() {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.prodotto.id;
    },
    nome_split() {
      return this.prodotto.nome.split(" in ");
    },
    n_totale_prodotto() {
      return this.prodotto.colori
        .filter((colore) => colore.qta)
        .map((col) => col.qta)
        .reduce((tot, num) => tot + num, 0);
    },
  },
  methods: {
    n_totale_consegna(consegna) {
      let prodotto = consegna.prodotti.find(
        (prodotto) => prodotto.id_prodotto == this.prodotto.id
      );
      if (!prodotto) {
        return 0;
      }
      return prodotto.colori
        .map((colore) => colore.qta)
        .reduce((tot, num) => tot + num, 0);
    },
    n_contato_consegna(id_consegna) {
      return this.$store.getters.get_prodotto_in_conteggio_cris({
        id_consegna: id_consegna,
        id_prodotto: this.prodotto.id,
      });
    },
  },
};
</script>