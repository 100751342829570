<template>
  <v-dialog v-model="dialog_calendar" width="1800" :scrollable="type == 'week'">
    <template v-slot:activator="{ on }">
      <v-btn color="amber" outlined v-on="on" class="mr-4"
        ><v-icon class="mr-2">mdi-calendar</v-icon>Calendario</v-btn
      >
    </template>
    <v-card>
      <v-card-title
        >Calendario Consegne

        <v-btn outlined color="purple" class="ml-4" @click="type = 'month'"
          >Mese</v-btn
        >
        <v-btn outlined color="green" class="mx-4" @click="type = 'week'"
          >Settimana</v-btn
        >
        <v-spacer></v-spacer>
        <template v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </template>

        <v-btn icon @click="$refs.calendar.prev()"
          ><v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-btn icon @click="$refs.calendar.next()"
          ><v-icon>mdi-chevron-right</v-icon></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-calendar
          locale="it-IT"
          v-model="value"
          ref="calendar"
          :type="type"
          :events="consegne"
          :event-more="false"
          @click:event="showEvent"
          :first-interval="7"
          :interval-count="12"
        >
          <template v-slot:event="{ event }">
            <span class="px-1">{{ event.name }}</span>
          </template>
          <template v-slot:day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: '286px' }"
            ></div>
          </template>
        </v-calendar>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="dialog_calendar = false">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card color="grey lighten-4" min-width="350px" flat>
        <v-toolbar :color="selectedEvent.color" dark>
          <v-toolbar-title>
            {{ selectedEvent.name }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text v-if="selectedOpen">
          <v-menu
            width="200"
            :close-on-content-click="false"
            v-model="menu_data_ora"
            
          >
            <template v-slot:activator="{ on }">
              <v-row
                v-on="on"
                class="mt-6 mb-4 text-h6 grey--text"
                style="cursor: pointer"
                no-gutters
                @click="set_giorno_consegna(selectedEvent)"
              >
                <v-col cols="12">
                  <v-icon class="mr-2" color="grey">mdi-calendar</v-icon>
                  {{ get_data(selectedEvent.start_ts) }}
                </v-col>
                <v-col cols="12">
                  <v-icon class="mr-2" color="grey">mdi-clock</v-icon>
                  {{ get_ora(selectedEvent.start_ts) }}
                </v-col>
              </v-row>
            </template>
            <v-card>
              <v-card-text>
                <v-date-picker
                  v-if="menu_data_ora"
                  v-model="giorno_consegna"
                  scrollable
                  locale="it-it"
                  class="pa-2"
                  first-day-of-week="1"
                  @
                />
                <v-time-picker
                  :disabled="!giorno_consegna"
                  :value="current_hour(selectedEvent) + ':00'"
                  v-if="menu_data_ora"
                  locale="it-it"
                  format="24hr"
                  @click:hour="cambia_data($event, selectedEvent)"
                  :allowed-hours="[8, 10, 14, 16, 18]"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text color="grey" @click="menu_data_ora = false"
                  >Annulla</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-row class="mb-6 text-h6 grey--text">
            <v-col cols="12">
              <v-icon color="grey" class="mr-2">mdi-cart</v-icon>
              {{ tot_cc(selectedEvent) }}
            </v-col>
          </v-row>
          <p>
            <v-btn
              @click="$refs.dettaglio_prenot.open()"
              outlined
              color="green"
            >
              <v-icon class="mr-2">mdi-open-in-new</v-icon>
              Visualizza
            </v-btn>
            <DettaglioPrenotazione
              ref="dettaglio_prenot"
              :prenotazione="get_prenotazione(selectedEvent.prenot_id)"
            />
          </p>
          <!-- <p>
            <v-btn outlined color="purple" @click="evadi"
              ><v-icon class="mr-2">mdi-truck</v-icon>Evadi</v-btn
            >
          </p> -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="grey" @click="selectedOpen = false">
            Chiudi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-dialog>
</template>

<script>
import moment from "moment";
import DettaglioPrenotazione from "./dettaglio_prenotazione/dettaglio_prenotazione_dialog.vue";
export default {
  components: {
    DettaglioPrenotazione,
  },
  props: {
    anno_rif: String,
  },
  data() {
    return {
      selectedOpen: false,
      selectedElement: null,
      selectedEvent: {},
      dialog_calendar: false,
      type: "month",
      value: "",
      data_presunta: null,
      giorno_consegna: null,
      ora_consegna: null,
      menu_data_ora: false,
    };
  },
  mounted() {},
  watch: {
    dialog_calendar: function () {
      if (this.dialog_calendar) {
        setTimeout(() => {
          this.$refs.calendar.checkChange();
        }, 100);
      }
    },
  },
  computed: {
    prenotazioni() {
      return this.$store.getters.get_prenotazioni_crisantemi;
    },
    consegne() {
      let array = [];
      this.prenotazioni
        .filter((prenot) => !prenot.evasa)
        .forEach((prenot) => {
          if (!prenot.consegne.length) {
            array.push({
              name:
                this.$store.getters.get_cliente_by_iva(prenot.cliente).numero +
                " - " +
                this.$store.getters.get_cliente_by_iva(prenot.cliente).nome,
              prenot_id: prenot.id,
              start: moment
                .unix(prenot.ts_consegna_presunta)
                .format("YYYY-MM-DD"),
              start_ts: prenot.ts_consegna_presunta * 1000,
              color: prenot.ritiro ? "green" : "blue",
            });
          } else {
            prenot.consegne.forEach((cons, index) => {
              array.push({
                name:
                  this.$store.getters.get_cliente_by_iva(prenot.cliente)
                    .numero +
                  " - " +
                  this.$store.getters.get_cliente_by_iva(prenot.cliente).nome +
                  " " +
                  (index + 1) +
                  "/" +
                  prenot.consegne.length,
                prenot_id: prenot.id,
                start: moment.unix(cons.ts_data).format("HH:mm") == '00:00' ?  moment.unix(cons.ts_data).format("YYYY-MM-DD") : moment.unix(cons.ts_data).format("YYYY-MM-DD HH:mm"),
                end: moment
                  .unix(cons.ts_data)
                  .add(2, "hours")
                  .format("YYYY-MM-DD HH:mm"),
                start_ts: cons.ts_data * 1000,
                consegna_id: cons.id,
                color: prenot.ritiro ? "green" : "blue",
              });
            });
          }
        });
      return array;
    },
  },
  methods: {
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    get_prenotazione(id_prenotazione) {
      let pren =
        this.$store.getters.get_prenotazione_crisantemi_by_id(id_prenotazione);
      return pren;
    },
    get_data(ts) {
      return moment.unix(ts / 1000).format("dddd D MMMM");
    },
    get_ora(ts) {
      return moment.unix(ts / 1000).format("HH:mm");
    },
    current_hour(selectedEvent) {
      return moment.unix(selectedEvent.start_ts / 1000).format("HH");
    },
    current_data(selectedEvent) {
      return moment.unix(selectedEvent.start_ts / 1000).format("YYYY-MM-DD");
    },
    set_giorno_consegna(selectedEvent){
      this.giorno_consegna = moment.unix(selectedEvent.start_ts/1000).format("YYYY-MM-DD")
    },
    cambia_data(event, selectedEvent) {
      event = event < 10 ? "0" + event : event;
      this.ora_consegna = event + ":00";
      this.menu_data_ora = false;
      let ts = moment(this.giorno_consegna + " " + this.ora_consegna).format(
        "X"
      );
      if ("consegna_id" in selectedEvent) {
        this.$store
          .dispatch("cambia_data_consegna_crisantemi", {
            id_prenotazione: selectedEvent.prenot_id,
            id_consegna: selectedEvent.consegna_id,
            data_consegna: ts,
          })
          .then(() => {
            this.selectedOpen = false;
          });
      } else {
        this.$store
          .dispatch("cambia_data_prenotazione_crisantemi", {
            id_prenotazione: selectedEvent.prenot_id,
            ts_data: ts,
          })
          .then(() => {
            this.selectedOpen = false;
          });
      }
      this.giorno_consegna = null;
    },
    tot_cc(event) {
      if ("consegna_id" in event) {
        return this.$store.getters.get_totCC_consegna({
          id_prenotazione: event.prenot_id,
          id_consegna: event.consegna_id,
        });
      } else {
        return this.$store.getters.get_totCC_prenotazione(event.prenot_id);
      }
    },
  },
};
</script>

<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>