<template>
  <v-row>
    <template v-for="colore in prodotto.colori">
      <v-col cols="4" :key="colore.id">
        <ExtraItemColor :colore="colore" :prodotto="prodotto.id" :anno_rif="anno_rif" :cliente="cliente"/>
      </v-col>
      <v-divider vertical :key="colore.id+'-div'"/>
    </template>
  </v-row>
</template>

<script>
import ExtraItemColor from "./extra_item_color.vue";
export default {
  components: {
    ExtraItemColor,
  },
  props: {
    id: String,
    cliente: String,
    anno_rif: String
  },
  computed: {
    prodotto() {
      return this.$store.getters.get_prodotto_by_id(this.id);
    },
  },
};
</script>
