<template>
  <div>
    <v-list-item
      @click="show_color = !show_color"
      :class="show_color ? 'green lighten-5' : ''"
    >
      <v-list-item-avatar>
        <v-img :src="avatar_img" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ nome_split[0] }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action-text style="font-size: 24px" class="pr-2">
        {{ tot_numero_prodotto }}
      </v-list-item-action-text>
    </v-list-item>
    <template v-if="show_color">
      <template v-for="colore in prodotto.colori">
        <ElementoColore
          :key="colore.id"
          :colore="colore"
          :prodotto="prodotto.id"
          :anno_rif="anno_rif"
          :cliente="cliente"
        />
      </template>
    </template>
  </div>
</template>

<script>
import ElementoColore from "./elemento_colore_nuovo_ordine.vue";
export default {
  components: {
    ElementoColore,
  },
  props: {
    prodotto: Object,
    cliente: String,
    anno_rif: String,
  },
  data() {
    return {
      show_color: true,
    };
  },
  computed: {
    avatar_img() {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.prodotto.id;
    },
    nome_split() {
      return this.prodotto.nome.split(" in ");
    },
    tot_numero_prodotto() {
      return this.$store.getters.get_numero_prenotato_prodotto_crisantemo({
        cliente: this.cliente,
        prodotto: this.prodotto.id,
      });
    },
  },
};
</script>