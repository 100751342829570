<template>
  <v-list-item class="ml-8">
    <v-list-item-avatar>
      <v-img :src="avatar_colore"></v-img>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ colore.nome }}
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-row class="" no-gutters align="center">
        <v-col cols="6"></v-col>
        <v-col cols="2">
          <span :class="qta_prodotta - ordinato.qta < 0 ? 'red--text' : 'green--text'">
            {{qta_prodotta - ordinato.qta}}
          </span>
        </v-col>
        <v-col cols="4" >
          <v-text-field
            v-model="qta_colore"
            dense
            hide-details
            outlined
            :loading="loading_qta"
            @focus="$event.target.select()"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    colore: Object,
    prodotto: String,
    cliente: String,
    anno_rif: String,
  },
  created() {
    this.debounceqta = _.debounce(this.set_qta_colore, 1500);
  },
  data() {
    return {
      qta_to_send: null,
      loading_qta: false,
    };
  },
  watch: {
    qta_to_send: function () {
      if (isNaN(this.qta_colore)) {
        return;
      }
      if (this.qta_colore < 0) {
        this.qta_colore = 0;
      } else if (this.qta_colore > 500) {
        this.qta_colore = 500;
      }
      this.debounceqta();
    },
    show: function () {
      if (this.show) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    },
  },
  computed: {
    qta_colore: {
      set(qta) {
        this.qta_to_send = qta;
        // this.loading_qta = true;
        // this.$store
        //   .dispatch("invio_colore_crisantemi", {
        //     anno: this.anno_rif,
        //     iva: this.cliente,
        //     id_prodotto: this.prodotto,
        //     id_colore: this.colore.id,
        //     qta: qta,
        //   })
        //   .finally(() => {
        //     this.loading_qta = false;
        //   });
      },
      get() {
        return this.$store.getters.get_numero_prenotato_crisantemo({
          cliente: this.cliente,
          prodotto: this.prodotto,
          colore: this.colore.id,
        });
      },
    },
    avatar_colore() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto +
        "/img_colore/" +
        this.colore.id
      );
    },
    ordinato() {
      return this.$store.getters.get_qta_dispo_ordinata_crisantemo({
        anno: this.anno_rif,
        prodotto: this.prodotto,
        colore: this.colore.id,
      });
    },
    qta_prodotta() {
      return this.$store.getters.get_qta_dispo_crisantemo({
        anno: this.anno_rif,
        prodotto: this.prodotto,
        colore: this.colore.id,
      });
    },
  },
  methods: {
    set_qta_colore() {
      this.loading_qta = true;
      this.$store
        .dispatch("invio_colore_crisantemi", {
          anno: this.anno_rif,
          iva: this.cliente,
          id_prodotto: this.prodotto,
          id_colore: this.colore.id,
          qta: this.qta_to_send,
        })
        .finally(() => {
          this.loading_qta = false;
        });
    },
  },
};
</script>