<template>
  <v-card>
    <v-card-title class="grey--text text-subtitle-1">
      Crisantemi
      <v-spacer />
      <CalendarioCrisantemi :anno_rif="anno_rif" />
      <GestioneDispo :anno_rif="anno_rif" />
      <v-btn outlined color="green" @click="$refs.nuovo_ordine.open()"
        ><v-icon class="mr-1">mdi-plus</v-icon>Nuovo</v-btn
      >
      <NuovoOrdine ref="nuovo_ordine" />
    </v-card-title>
    <v-card-subtitle>
      <v-row no-gutters>
        <v-col cols="3">
          Anno di Riferimento:
          <v-menu>
            <template v-slot:activator="{ on }">
              <span
                v-on="on"
                style="cursor: pointer; font-size: 20px"
                class="green--text ml-4"
                ><b>{{ anno_rif }}</b>
              </span>
            </template>
            <v-list>
              <v-list-item
                v-for="anno in anni"
                :key="anno"
                @click="anno_rif = anno"
              >
                <v-list-item-avatar>
                  <v-icon>mdi-calendar</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ anno }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- <v-select
            v-model="anno_rif"
            :items="anni"
            label="Anno Riferimento"
            outlined
            dense
            hide-details=""
          ></v-select> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field
            hide-details
            outlined
            label="Cerca ..."
            v-model="find_nome"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-switch
            inset
            hide-details
            label="Nascondi Evase"
            v-model="hide_evase"
            @change="set_hide_pref()"
          ></v-switch>
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>
      <v-divider class="mb-4" />
      <v-row class="font-weight-medium" no-gutters>
        <v-col cols="1" class=""> # Cliente </v-col>
        <v-col cols="3"> Cliente </v-col>
        <v-col cols="2"> Consegna Presunta</v-col>
        <v-col cols="1">Ritiro</v-col>
        <v-col cols="1"> Anno di Rif </v-col>
        <v-col cols="1" class="">CC</v-col>
        <v-col cols="1" class=""> Totale </v-col>
      </v-row>
      <v-divider class="my-4" />
      <template v-for="prenotazione in prenotazioni">
        <v-lazy
          :key="prenotazione.id"
          :min-height="30"
          :options="{ threshold: 0.5 }"
          transition="fade-transition"
        >
          <PrenotazioneElement :prenotazione="prenotazione" />
        </v-lazy>
      </template>
      <v-row no-gutters class="mt-6">
        <v-col cols="2" offset="9"> <b>{{ somma_totale.toFixed(2) }} €</b> </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import NuovoOrdine from "./nuovo_ordine/nuovo_ordine_crisantemi.vue";
import PrenotazioneElement from "./prenotazione_element.vue";
import GestioneDispo from "./gestione_disponibilità/dialog_gestione_dispo.vue";
import CalendarioCrisantemi from "./calendario_prenotazioni.vue";
import moment from "moment";
export default {
  components: {
    NuovoOrdine,
    PrenotazioneElement,
    GestioneDispo,
    CalendarioCrisantemi,
  },
  data() {
    return {
      anno_rif: moment().format("YYYY"),
      find_nome: "",
      hide_evase: false,
    };
  },
  watch: {
    anno_rif: function () {
      this.$store.dispatch("fetch_prenotazioni_crisantemi", this.anno_rif);
    },
  },
  mounted() {
    this.$store.dispatch(
      "fetch_prenotazioni_crisantemi",
      moment().format("YYYY")
    );
    this.$store.dispatch("fetch_dispo_crisantemi", moment().format("YYYY"));
  },
  computed: {
    anni() {
      let adesso = moment().format("YYYY");
      return [...Array(7).keys()].map((anno) => (adesso - anno).toString());
    },
    prenotazioni() {
      return this.$store.getters.get_prenotazioni_crisantemi
        .slice()
        .filter((pren) => {
          if (!this.hide_evase) {
            return true;
          } else {
            return !pren.evasa;
          }
        })
        .filter((pren) => {
          if (this.find_nome.length <= 2) {
            return true;
          } else {
            let regex = new RegExp(this.find_nome, "i");
            return regex.test(
              this.$store.getters.get_cliente_by_iva(pren.cliente).nome
            );
          }
        })
        .sort(
          (a, b) =>
            parseInt(this.$store.getters.get_cliente_by_iva(a.cliente).numero) -
            parseInt(this.$store.getters.get_cliente_by_iva(b.cliente).numero)
        );
    },
    somma_totale() {
      return this.prenotazioni
        .map((pren) => {
          let listino = this.$store.getters.get_cliente_by_iva(
            pren.cliente
          ).listino;
          return pren.prodotti
            .map((prod) => {
              let prodotto = this.$store.getters.get_prodotto_by_id(
                prod.id_prodotto
              );
              let prezzo = 0;
              switch (listino) {
                case "1":
                  prezzo = prodotto.prezzo1;
                  break;
                case "2":
                  prezzo = prodotto.prezzo2;
                  break;
                case "3":
                  prezzo = prodotto.prezzo3;
                  break;
              }
              return parseFloat(
                prod.colori.map((col) => col.qta).reduce((a, b) => a + b, 0) *
                  prezzo
              );
            })
            .reduce((a, b) => a + b, 0);
        })
        .reduce((a, b) => a + b, 0);
    },
  },
  methods: {
    set_hide_pref() {
      localStorage.setItem("hide_evase", this.hide_evase);
    },
  },
};
</script>
