<template>
  <v-dialog v-model="dialog" width="1300" scrollable>
    <v-card>
      <v-card-title class="grey--text text-subtitle-1">
        Prenotazione Crisantemi {{colore_sel}}
      </v-card-title>
      <v-card-subtitle>
        <v-row class="" align="center">
          <v-col cols="4">
            <h3>
              <v-icon class="mr-2">mdi-account</v-icon>{{ cliente.numero }} -
              {{ cliente.nome }}
            </h3>
          </v-col>
          <v-col cols="4"> </v-col>
          <v-col cols="4">
            <v-switch inset label="Mostra Tutto" v-model="mostra_tutto" />
          </v-col>
        </v-row>
        <v-divider class="mb-4" />
        <v-row class="ml-1 mb-1" align="center">
          <v-col cols="4">Nome</v-col>
          <v-divider vertical />
          <template v-for="(consegna, index) in prenotazione.consegne">
            <v-col cols="1" :key="consegna.id" class="d-flex justify-center">
              <v-menu
                :close-on-content-click="false"
                v-model="dialog_menu_array[index]"
              >
                <template v-slot:activator="{ on }">
                  <v-row no-gutters v-on="on" style="cursor: pointer">
                    <v-col cols="12">
                      <span>
                        <v-icon size="20" class="mr-1">mdi-calendar</v-icon
                        ><b>{{ giorno(consegna.ts_data) }}</b>
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <v-icon size="20" class="mr-1">mdi-cart</v-icon>
                      {{ totale_cc_consegna(consegna.prodotti).toFixed(1) }}
                    </v-col>
                    <v-col cols="12" v-if="consegna.id_ordine_creato">
                      <template v-if="!ordine_loading">
                        <v-icon size="20" class="mr-1">mdi-clipboard</v-icon>
                        # {{ get_ordine(consegna.id_ordine_creato).numero }}
                        <v-icon
                          class="mb-1"
                          color="blue"
                          size="15"
                          v-if="consegna.id_conteggio"
                          >mdi-check-decagram</v-icon
                        >
                      </template>
                      <template v-else>
                        <v-progress-circular
                          width="1"
                          indeterminate
                          size="20"
                        />
                      </template>
                    </v-col>
                  </v-row>
                </template>
                <v-list>
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on">
                        <v-list-item-avatar>
                          <v-icon>mdi-calendar</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title> Cambia Data </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <v-date-picker
                      v-model="data_consegna"
                      @change="set_data_consegna(consegna.id)"
                    ></v-date-picker>
                  </v-menu>
                  <v-list-item @click="stampa_consegna(consegna.id)">
                    <v-list-item-avatar
                      ><v-icon color="purple"
                        >mdi-printer</v-icon
                      ></v-list-item-avatar
                    >
                    <v-list-item-content>
                      <v-list-item-title>Stampa</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="crea_ordine(consegna.id)"
                    v-if="!consegna.id_ordine_creato"
                  >
                    <v-list-item-avatar>
                      <v-btn icon :loading="loading_crea_ordine">
                        <v-icon color="blue">mdi-clipboard-text-outline</v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> Crea Ordine </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="consegna.id_conteggio"
                    @click="apri_conteggio(consegna.id_ordine_creato)"
                  >
                    <v-list-item-avatar>
                      <v-icon color="blue">mdi-clipboard</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> Conteggio </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="riempi_rimanenza(consegna.id)"
                    v-if="!consegna.id_ordine_creato"
                  >
                    <v-list-item-avatar>
                      <v-btn icon :loading="loading_rimanenza">
                        <v-icon color="green">mdi-package-down</v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title> Rimanenza </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="elimina_consegna(consegna.id)">
                    <v-list-item-avatar>
                      <v-btn icon :loading="loading_elimina_consegna">
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>Elimina</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-divider :key="consegna.id + '-div'" vertical />
          </template>
          <v-col cols="1">
            <v-btn
              :loading="loading_crea_consegna"
              outlined
              color="purple"
              @click="crea_consegna()"
              v-if="prenotazione.consegne.length < 5"
              >Consegna</v-btn
            >
          </v-col>

          <v-col
            cols="2"
            :offset="5 - prenotazione.consegne.length"
            class="d-flex justify-center"
          >
            In Ordine
          </v-col>
        </v-row>
        <v-divider class="mb-3" />
      </v-card-subtitle>

      <v-card-text class="mt-n6">
        <GruppoDiametro
          v-for="categ in lista_prodotti.crisantemi"
          :key="categ.id"
          :categ="categ"
          :consegne="prenotazione.consegne"
          :cliente="prenotazione.cliente"
          :anno_rif="prenotazione.anno"
          :mostra_tutto="mostra_tutto"
          :prenotazione="prenotazione.id"
          :colore_sel="colore_sel"
        />

        <!-- Lista Extra Crisantemi -->

        <v-list-item v-if="lista_prodotti.extraprodotti.length">
          <v-list-item-avatar
            ><v-icon>mdi-bucket-outline</v-icon></v-list-item-avatar
          >
          <v-list-item-content>
            <v-list-item-title class="font-italic grey--text">
              Altri Prodotti
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon color="grey" @click="show_extra = !show_extra">
              <v-icon>{{
                show_extra ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <template v-if="show_extra">
          <ElementoProdottoExtra
            v-for="prodotto in lista_prodotti.extraprodotti"
            :key="prodotto.id"
            :prodotto="prodotto"
            :consegne="prenotazione.consegne"
            :cliente="prenotazione.cliente"
            :anno_rif="prenotazione.anno"
            :mostra_tutto="mostra_tutto"
            :prenotazione="prenotazione.id"
          />
        </template>
        <v-row class="mt-4">
          <v-col cols="10">
            <v-text-field
              outlined
              label="Note"
              v-model="text_nota"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-dialog v-model="dialog_aggiungi" width="1200">
          <template v-slot:activator="{ on }">
            <v-btn outlined color="green" v-on="on"
              ><v-icon class="ml-2">mdi-plus</v-icon>Aggiungi ...</v-btn
            >
          </template>
          <v-card>
            <v-card-title class="grey--text text-subtitle-1">
              Aggiungi prodotto a ordine
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="extra_item_sel"
                    :items="prodotti_nocris"
                    item-text="nome"
                    item-value="id"
                    outlined
                  />
                </v-col>
              </v-row>

              <template v-if="extra_item_sel">
                <ExtraItem
                  :id="extra_item_sel"
                  :cliente="prenotazione.cliente"
                  :anno_rif="prenotazione.anno"
                />
              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="grey"
                class="mb-2"
                text
                @click="dialog_aggiungi = false"
                >chiudi</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn outlined color="purple" @click="stampa_prenotazione()"
          ><v-icon class="mr-2">mdi-printer</v-icon>stampa</v-btn
        >
        <v-btn text color="grey" @click="close()" class="my-2">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="alredy_exist"
      class="mt-16"
      color="red"
      outlined
      top
      timeout="2000"
      text
    >
      <v-icon color="red" class="mr-2">mdi-alert</v-icon> L'ordine esiste già
    </v-snackbar>
    <DettaglioConteggio
      v-if="id_ordine_conteggio_attivo"
      ref="conteggio"
      :ordine="get_ordine(id_ordine_conteggio_attivo)"
    />
  </v-dialog>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import GruppoDiametro from "./gruppo_diametro_vaso.vue";
import ElementoProdottoExtra from "./elemento_prodotto.vue";
import ExtraItem from "../nuovo_ordine/extra_item_element.vue";
import printer from "../../../../service/printer";
import DettaglioConteggio from "../conteggi_crisantemi/dettaglio_conteggio_crisantemi.vue";
export default {
  components: {
    GruppoDiametro,
    ElementoProdottoExtra,
    ExtraItem,
    DettaglioConteggio,
  },
  props: {
    prenotazione: Object,
    colore_sel: String
  },
  data() {
    return {
      dialog: false,
      mostra_tutto: false,
      data_consegna: null,
      extra_item_sel: null,
      dialog_aggiungi: false,
      show_extra: true,
      loading_crea_ordine: false,
      loading_crea_consegna: false,
      loading_elimina_consegna: false,
      menu_consegna: false,
      text_nota: this.prenotazione.nota,
      alredy_exist: false,
      ordine_loading: true,
      conteggio_loading: true,
      loading_rimanenza: false,
      id_ordine_conteggio_attivo: null,
      dialog_menu_array: [],
    };
  },
  created() {
    this.debouncenota = _.debounce(this.set_nota, 2000);
  },
  mounted() {},
  watch: {
    text_nota: function () {
      this.debouncenota();
    },
    dialog: function () {
      if (this.ordine_loading) {
        Promise.all(
          this.prenotazione.consegne.map((consegna) => {
            if (consegna.id_ordine_creato) {
              return this.$store.dispatch(
                "fetch_ordine_by_id",
                consegna.id_ordine_creato
              );
            }
            return null;
          })
        ).finally(() => {
          this.ordine_loading = false;
        });
        Promise.all(
          this.prenotazione.consegne.map((consegna) => {
            return this.$store.dispatch(
              "fetch_conteggio_cris_by_id_consegna",
              consegna.id
            );
          })
        ).then(() => {
          this.conteggio_loading = false;
        });
      }
    },
  },
  computed: {
    prodotti_nocris() {
      return this.$store.getters.get_lista_prodotti_raw.filter(
        (prod) => prod.sotto_categoria != "Crisantemo"
      );
    },
    cliente() {
      return this.$store.getters.get_cliente_by_iva(this.prenotazione.cliente);
    },
    lista_prodotti() {
      var obj = { crisantemi: [], extraprodotti: [] };
      let lista_cris = this.$store.getters.get_lista_prodotti_raw
        .filter((prodotto) => prodotto.sotto_categoria == "Crisantemo")
        .map((prodotto) => {
          let prenotato = this.prenotazione.prodotti.find(
            (prenotato) => prenotato.id_prodotto == prodotto.id
          );
          if (prenotato) {
            return {
              ...prodotto,
              prenotato: true,
              colori: prodotto.colori.map((colore) => {
                let colore_prenotato = prenotato.colori.find(
                  (col) => col.id_colore == colore.id
                );
                if (colore_prenotato) {
                  return {
                    ...colore,
                    qta: colore_prenotato.qta,
                    nota: colore_prenotato.nota,
                  };
                } else {
                  return { ...colore };
                }
              }),
            };
          } else {
            return { ...prodotto };
          }
        });
      var res = _.groupBy(lista_cris, "diametro");
      Object.keys(res).forEach((key) => {
        if (res[key].some((prod) => prod.prenotato) || this.mostra_tutto) {
          obj.crisantemi.push({
            diametro: key,
            prodotti: res[key].filter(
              (prod) => prod.prenotato || this.mostra_tutto
            ),
          });
        }
      });
      this.prenotazione.prodotti
        .filter((prod) => {
          return (
            this.$store.getters.get_prodotto_by_id(prod.id_prodotto)
              .sotto_categoria != "Crisantemo"
          );
        })
        .forEach((prod) => {
          let prodotto = this.$store.getters.get_prodotto_by_id(
            prod.id_prodotto
          );
          obj.extraprodotti.push({
            ...prodotto,
            colori: prod.colori.map((col) => {
              return {
                ...prodotto.colori.find((c) => c.id == col.id_colore),
                qta: col.qta,
              };
            }),
          });
        });

      return obj;
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit("close")
    },
    giorno(ts) {
      return moment.unix(ts).format("DD/MM");
    },
    crea_consegna() {
      this.loading_crea_consegna = true;
      this.$store
        .dispatch("crea_consegna_prenotazione_crisantemi", {
          id_prenotazione: this.prenotazione.id,
        })
        .finally(() => {
          this.loading_crea_consegna = false;
        });
    },
    elimina_consegna(id) {
      this.loading_elimina_consegna = true;
      this.$store
        .dispatch("elimina_consegna_prenotazione_crisantemi", {
          id_prenotazione: this.prenotazione.id,
          id_consegna: id,
        })
        .finally(() => {
          this.dialog_menu_array = [];
          this.loading_elimina_consegna = false;
        });
    },
    set_data_consegna(id) {
      this.$store.dispatch("cambia_data_consegna_crisantemi", {
        id_prenotazione: this.prenotazione.id,
        id_consegna: id,
        data_consegna: moment(this.data_consegna).format("X"),
      });
    },
    set_nota() {
      this.$store
        .dispatch("invio_nota_crisantemi", {
          anno: this.prenotazione.anno,
          iva: this.prenotazione.cliente,
          nota: this.text_nota,
        })
        .then(() => {
          this.menu_nota = false;
        });
    },
    stampa_consegna(id_consegna) {
      printer.print_pdf_consegna_crisantemi({
        id_prenotazione: this.prenotazione.id,
        id_consegna: id_consegna,
      });
    },
    stampa_prenotazione() {
      printer.print_pdf_ordine_crisantemi(this.prenotazione.id);
    },
    crea_ordine(id_consegna) {
      this.loading_crea_ordine = true;
      this.$store
        .dispatch("crea_ordine_da_consegna_prenotazione", {
          id_consegna: id_consegna,
          id_prenotazione: this.prenotazione.id,
        })
        .then(() => {
          // this.$router.push("/ordini");
          this.loading_crea_ordine = false;
        })
        .catch(() => {
          this.loading_crea_ordine = false;
          this.alredy_exist = true;
        });
    },
    totale_cc_consegna(prodotti) {
      return prodotti
        .map((prod) => {
          return (
            prod.colori
              .map((col) => col.qta)
              .reduce((tot, num) => tot + num, 0) /
            this.$store.getters.get_prodotto_by_id(prod.id_prodotto).qta_cc
          );
        })
        .reduce((tot, num) => tot + num, 0);
    },
    riempi_rimanenza(id_consegna) {
      this.loading_rimanenza = true;
      this.$store
        .dispatch("riempi_rimanenza_consegna_crisantemi", {
          id_prenotazione: this.prenotazione.id,
          id_consegna: id_consegna,
        })
        .then(() => {
          this.loading_rimanenza = false;
          this.dialog_menu_array = [];
        });
    },
    // numero_ordine(id_ordine) {
    //   return this.$store.getters.get_ordine_by_id(id_ordine).numero;
    // },
    get_ordine(id_ordine) {
      return this.$store.getters.get_ordine_by_id(id_ordine);
    },
    apri_conteggio(id_ordine) {
      this.id_ordine_conteggio_attivo = id_ordine;
      setTimeout(() => {
        this.$refs.conteggio.open();
      }, 200);
    },
  },
};
</script>
