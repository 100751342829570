<template>
  <div>
    <v-list-item>
      <v-list-item-avatar
        ><v-icon>mdi-bucket-outline</v-icon></v-list-item-avatar
      >
      <v-list-item-content>
        <v-list-item-title class="font-italic grey--text">
          Vaso Ø {{ categ.diametro }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon color="grey" @click="show = !show"
          ><v-icon>{{
            show ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon></v-btn
        >
      </v-list-item-action>
    </v-list-item>
    <template v-if="show">
      <template v-for="prod in categ.prodotti">
        <ElementoProdotto
          :prodotto="prod"
          :key="prod.id"
          :anno_rif="anno_rif"
        />
      </template>
    </template>
    <v-divider class="mt-4" />
  </div>
</template>

<script>
import ElementoProdotto from "./elemento_prodotto_dispo.vue";
export default {
  components: {
    ElementoProdotto,
  },
  props: {
    categ: Object,
    anno_rif: String,
    expand_all: Boolean,
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    expand_all: function () {
      this.show = this.expand_all;
    },
  },
};
</script>