var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{class:[_vm.prenotazione.evasa ? 'text-decoration-line-through grey--text text--lighten-1' : '',  hover ? 'green lighten-5' : ''],attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"pl-2",attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.cliente.numero)+" ")]),_c('v-col',{attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(_vm.cliente.nome)+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"cursor":"pointer"}},on),[_vm._v(" "+_vm._s(_vm.prenotazione.ts_consegna_presunta ? _vm.data_presunta_format : "Non Specificato")+" ")])]}}],null,true)},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"it-it"},on:{"input":function($event){_vm.modal_date = false}},model:{value:(_vm.data_presunta),callback:function ($$v) {_vm.data_presunta=$$v},expression:"data_presunta"}})],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-menu',{attrs:{"min-width":"200","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({class:!_vm.prenotazione.ritiro ? 'blue--text' : 'green--text',staticStyle:{"cursor":"pointer"}},on),[_vm._v(_vm._s(!_vm.prenotazione.ritiro ? "Consegna" : "Ritiro"))])]}}],null,true),model:{value:(_vm.menu_cambio_ritiro),callback:function ($$v) {_vm.menu_cambio_ritiro=$$v},expression:"menu_cambio_ritiro"}},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.set_ritiro(1)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Ritiro ")])],1),_c('v-list-item-action',[(_vm.loading_cambio_ritiro)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20","width":"2","color":"green"}}):_vm._e()],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.set_ritiro(0)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Consegna ")])],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.prenotazione.anno)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.tot_cc)+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(" "+_vm._s(_vm.totale_valore)+" € ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"icon":"","color":"blue"},on:{"click":function($event){return _vm.$refs.prenotazione_dettaglio.open()}}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1),_c('DettaglioPrenotazione',{ref:"prenotazione_dettaglio",attrs:{"prenotazione":_vm.prenotazione}}),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"grey","icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Elimina")])],1)],1)]}}],null,true),model:{value:(_vm.dialog_elimina),callback:function ($$v) {_vm.dialog_elimina=$$v},expression:"dialog_elimina"}},[_c('v-card',[_c('v-card-title',{staticClass:"red--text"},[_vm._v(" Elimina Prenotazione ")]),_c('v-card-text',[_vm._v(" La prenotazione verrà eliminata e non sarà possibile recuperarla. "),_c('br'),_vm._v(" Vuoi procedere? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.elimina_prenotazione()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-delete")]),_vm._v("Elimina")],1),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.dialog_elimina = false}}},[_vm._v("Annulla")])],1)],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.stampa()}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-printer")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Stampa")])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{model:{value:(_vm.evasa),callback:function ($$v) {_vm.evasa=$$v},expression:"evasa"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Evasa")])],1)],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }