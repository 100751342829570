<template>
  <div>
    <v-row align="center">
      <v-col cols="2">
        <v-avatar size="30">
          <v-img :src="avatar_colore" />
        </v-avatar>
      </v-col>
      <v-col cols="6">
        {{ colore.nome }}
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model="qta_color"
          dense
          outlined
          hide-details
          :loading="loading_qta"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    prodotto: String,
    colore: Object,
    cliente: String,
    anno_rif: String,
  },
  mounted() {
    this.debounceqta = _.debounce(this.set_qta_colore, 1500);
  },
  data() {
    return {
      qta_to_send: null,
      loading_qta: false,
    };
  },
  watch: {
    qta_to_send: function () {
      if (isNaN(this.qta_to_send)) {
        return;
      }
      if (this.qta_to_send < 0) {
        this.qta_to_send = 0;
      }
      this.debounceqta();
    },
  },
  computed: {
    avatar_colore() {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.prodotto +
        "/img_colore/" +
        this.colore.id
      );
    },
    qta_color: {
      get() {
        return this.$store.getters.get_numero_prenotato_crisantemo({
          cliente: this.cliente,
          prodotto: this.prodotto,
          colore: this.colore.id,
        });
      },
      set(qta) {
        this.qta_to_send = qta;
      },
    },
  },
  methods: {
    set_qta_colore() {
      this.loading_qta = true;
      this.$store
        .dispatch("invio_colore_crisantemi", {
          anno: this.anno_rif,
          iva: this.cliente,
          id_prodotto: this.prodotto,
          id_colore: this.colore.id,
          qta: this.qta_to_send,
        })
        .finally(() => {
          this.loading_qta = false;
        });
    },
  },
};
</script>
