<template>
  <CrisantemiDesktop v-if="!$vuetify.breakpoint.mobile" />
  <CrisantemiMobile v-else/>
</template>

<script>
import CrisantemiDesktop from "@/components/Crisantemi/desktop/crisantemi_desktop.vue"
import CrisantemiMobile from "@/components/Crisantemi/mobile/crisantemi_mobile.vue"
export default {
  components: {
    CrisantemiDesktop,
    CrisantemiMobile
  },
};
</script>