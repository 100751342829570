import { mapGetters } from "vuex";
import _ from "lodash";
import moment from "moment";
export default {
  data() {
    return {
      cliente_sel: null,
      numero_cliente: null,
      anno_rif: moment().format("YYYY").toString(),
      esiste: false,
      menu_clona: false,
      not_exist: false,
    };
  },
  computed: {
    ...mapGetters({
      //trasporto: 'get_trasporto',
      clienti: "get_clienti",
      prodotti: "get_lista_prodotti_raw",
      carrello: "get_carrello",
    }),
    prodotti_nocris() {
      return this.prodotti.filter(
        (prod) => prod.sotto_categoria != "Crisantemo"
      );
    },
    crisantemi() {
      let cris = this.prodotti.filter(
        (prod) => prod.sotto_categoria == "Crisantemo"
      );
      var obj = [];
      var res = _.groupBy(cris, "diametro");
      Object.keys(res).forEach((key) => {
        obj.push({ diametro: key, prodotti: res[key] });
      });
      return obj;
    },
    anni() {
      let adesso = moment().format("YYYY");
      return [...Array(5).keys()].map((anno) => adesso - anno);
    },
  },
  watch: {
    cliente_sel: function () {
      if (!this.cliente_sel) {
        return;
      }
      let cliente = this.$store.getters.get_cliente_by_iva(this.cliente_sel);
      this.numero_cliente = cliente.numero;
      this.$store
        .dispatch("fetch_prenotazione_crisantemi_by_iva", {
          cliente: this.cliente_sel,
          anno: this.anno_rif,
        })
        .then((res) => {
          if (res.length) {
            this.esiste = true;
            return;
          } else {
            this.esiste = false;
          }
        });
    },
    anno_rif: function () {
      if (!this.cliente_sel) {
        return;
      }
      this.$store
        .dispatch("fetch_prenotazione_crisantemi_by_iva", {
          cliente: this.cliente_sel,
          anno: this.anno_rif,
        })
        .then((res) => {
          if (res.length) {
            this.esiste = true;
            return;
          } else {
            this.esiste = false;
          }
        });
    },
  },
  methods: {
    avatar_img(id) {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + id;
    },
    nome_split(nome) {
      return nome.split(" in ");
    },
    avatar_color(id_colore, id_prodotto) {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        id_prodotto +
        "/img_colore/" +
        id_colore
      );
    },
    clona(anno) {
      this.$store
        .dispatch("clona_prenotazione_crisantemi", {
          iva: this.cliente_sel,
          da_anno: anno,
          a_anno: this.anno_rif,
        })
        .then((res) => {
          if (res.res == "ok") {
            this.$store.dispatch(
              "fetch_prenotazioni_crisantemi",
              this.anno_rif
            );
            this.cliente_sel = null;
            this.numero_cliente = null;
            this.dialog = false;
          } else {
            this.not_exist = true;
            setTimeout(() => {
              this.not_exist = false;
            }, 2000);
          }
        });
    },
  },
};
